<dx-app>
  <app-header
      title="Meine DVAG"
      [favoritesUrl]="favoritesUrl"
      [appDefaultSearchUrl]="appDefaultSearchUrl"
      [benutzerkontoUrl]="benutzerkontoUrl"
      [appEditUrl]="appEditUrl"
      [avatarUrl]="avatarUrl"
      [logoffUrl]="logoffUrlKeycloak.toString()"
      [showFavorites]="true"
      [showUser]="true"
      [showNavigation]="true"
      [hideBenutzerkonto]="false"
      [logo]="'image'"
      [logWithInsightsService]="false"
      [headerTheme]="headerTheme"
      [apps]="favApps"
      [favorites]="favorites"
      [showchangerolebutton]="showchangerolebutton"
      (navigationMenuInteraction)="writeGABurger()"
      (userMenuInteraction)="writeGAUser()"
      (favoritesMenuInteraction)="writeGAFavorites()"
      (changeUserClicked)="changeUser()"
      (gotoHomeLogoClicked)="gotoHomeLogo()"
      (sortAppsClicked)="trackSortFavorites()"
      (deleteAppClicked)="trackDeleteFavorite()"
      (benutzerkontoLinkClicked)="trackGotoBenutzerkonto()"
      (logoffClicked)="trackGotoLogoff()"
      (appEditClicked)="trackGotoApps()"
      (editAppsClicked)="trackEditApps()"
      (lauchAppClicked)="trackLaunchApp($event)"
      [showNavigationHeader]="showNavigationHeader"
      [showLinks]="true">
    </app-header>

  <dx-modal #texteditmodal height="content" id="text-edit-modal" label="Textbearbeitung">
    <div slot="content">
      <dx-single-select #editmodetoggle [value]="editModeToYesNo(editMode)" id="text-editmode-toggle" label="Text-ID's anzeigen:" size="s">
        <dx-single-select-item label="Ja" value="yes"></dx-single-select-item>
        <dx-single-select-item label="Nein" value="no"></dx-single-select-item>
      </dx-single-select>
    </div>
    <dx-button (click)="confirmText(texteditmodal, editmodetoggle)" id="text-edit-modal-confirm" label="Bestätigen" slot="primary-actions" type="primary-m">
    </dx-button>
  </dx-modal>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
  <app-userlane></app-userlane>
</dx-app>

