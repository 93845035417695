import {Routes} from '@angular/router'
import DashboardComponent from './dashboard/dashboard.component'
import {ControllerService as VBKennzahlenService} from './../generated/openapi/vbkennzahlen'
import {ENVIRONMENT_INITIALIZER, inject} from '@angular/core'

export const ROUTES: Routes = [
  {
    path: '',
    component: DashboardComponent,
    providers: [
      {
        provide: ENVIRONMENT_INITIALIZER, multi: true, useValue: () => {
          inject(VBKennzahlenService).configuration.withCredentials = true
        }
      }
    ]
  },
  {path: 'search', loadComponent: () => import('./search/search.component')},
  {path: 'search/:term', loadComponent: () => import('./search/search.component')},
  {path: 'apps', loadComponent: () => import('./apps/apps.component')},
  {path: 'not_found', loadComponent: () => import('./htmlerrorpages/htmlerrorpages.component')},
  {path: 'unauthorized', loadComponent: () => import('./htmlerrorpages/htmlerrorpages.component')},
  {path: 'forbidden', loadComponent: () => import('./htmlerrorpages/htmlerrorpages.component')},
  {path: 'error', loadComponent: () => import('./htmlerrorpages/htmlerrorpages.component')},
  {path: 'health', loadComponent: () => import('./health-page/health-page.component')},
  {path: 'autherrorpage', loadComponent: () => import('@dvag/uinfo-ui-lib').then(m => m.AuthErrorPageComponent)},
  {path: '*', pathMatch: 'full', loadComponent: () => import('./htmlerrorpages/htmlerrorpages.component')}
]
