import {
  APP_INITIALIZER,
  enableProdMode,
  ENVIRONMENT_INITIALIZER,
  ErrorHandler,
  importProvidersFrom,
  inject,
  LOCALE_ID
} from '@angular/core'
import { environment } from './environments/environment'
import { bootstrapApplication } from '@angular/platform-browser'
import { defineCustomElements as defineDesignSystemIcons } from '@dvag/design-system-icons/loader'
import { AppComponent } from './app/app.component'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { EnvironmentService } from './app/services/environment.service'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular'
import {
  GoogleAnalyticsInitializer,
  GtagFn,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  NGX_GTAG_FN
} from 'ngx-google-analytics'
import { DOCUMENT, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common'
import { AuthService } from './app/services/auth.service'
import { TextService } from './app/services/text.service'
import { FeaturetoggleService } from './app/services/featuretoggle.service'
import { TextServiceReaderService as TextserviceService } from './generated/openapi/textservice'
import { DefaultService as FavoritenService } from './generated/openapi/favoriten'
import { SucheRestService as SucheService } from './generated/openapi/search/search'
import { DefaultService as EcsTagsService } from './generated/openapi/tags'
import localeDe from '@angular/common/locales/de'
import {
  provideRouter
} from '@angular/router'
import { ROUTES } from './app/app.routes'
import { InsightsService } from './app/services/insights.service'
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js'
import { DesignSystemModule } from '@dvag/design-system-angular'
import { authIntercept } from './app/interceptor/auth-interceptor'
import { BrowsersizeService } from '@dvag/uinfo-ui-lib'

if (environment.production) {
  enableProdMode()
}

registerLocaleData(localeDe)
defineDesignSystemIcons(window)

class AppInsightsErrorHandler extends ApplicationinsightsAngularpluginErrorService {
  override handleError(error: Error): void {
    super.handleError(error)
    console.error(error)
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: doAuth,
      multi: true,
      deps: [AuthService]
    },
    importProvidersFrom(KeycloakAngularModule),
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [EnvironmentService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initMatomo,
      deps: [EnvironmentService, KeycloakService, DOCUMENT],
      multi: true
    },
    {
      provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
      useFactory: initGA,
      deps: [EnvironmentService, NGX_GTAG_FN, DOCUMENT],
      multi: true
    },
    {
      provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
      useFactory: initGA4,
      deps: [EnvironmentService, NGX_GTAG_FN, DOCUMENT],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadTextService,
      multi: true,
      deps: [TextService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadFeatureToggleService,
      multi: true,
      deps: [FeaturetoggleService]
    },
    InsightsService,
    {
      provide: ErrorHandler,
      useClass: AppInsightsErrorHandler
    },
    BrowsersizeService,
    {
      provide: Window,
      useValue: window
    },
    {provide: LOCALE_ID, useValue: 'de-DE'},
    provideHttpClient(withInterceptors([authIntercept])),
    provideRouter(ROUTES),
    importProvidersFrom(
      DesignSystemModule
    ),
    {provide: 'locationObject', useValue: location},
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => {
        inject(TextserviceService).configuration.withCredentials = true
        inject(FavoritenService).configuration.withCredentials = true
        inject(SucheService).configuration.withCredentials = true
        inject(EcsTagsService).configuration.withCredentials = true
      }
    }
  ]
})

export function loadConfig(envConfigService: EnvironmentService) {
  return () => envConfigService.getConfig()
}

export async function initGA(
  configService: EnvironmentService,
  gtag: GtagFn,
  doc: Document
): Promise<void> {
  const config = await configService.getConfig()
  const gaTag = <string>config.apis.gtmId
  return GoogleAnalyticsInitializer({trackingCode: gaTag}, gtag, doc)()
}

export async function initGA4(
  configService: EnvironmentService,
  gTag: GtagFn,
  doc: Document
): Promise<void> {
  const config = await configService.getConfig()
  const gtm4Id = <string>config.apis.gtm4Id
  return GoogleAnalyticsInitializer({trackingCode: gtm4Id}, gTag, doc)()
}

export function doAuth(authService: AuthService) {
  return () => authService.initializeKeycloak()
}

export function loadTextService(textService: TextService) {
  const editMode = (window.sessionStorage.getItem('text-editmode') == 'true')
  // For multilingual fallback get fallback-lang (de-DE) first and then preferred lang
  return () => textService.getTranslations('de-DE', editMode)
}

export function loadFeatureToggleService(featureToggleService: FeaturetoggleService) {
  return () => featureToggleService.init()
}

export function initMatomo(
  configService: EnvironmentService,
  keycloak: KeycloakService,
  doc: Document
) {
  return async (): Promise<void> => {
    const config = await configService.getConfig() // Config wird asynchron gelesen. Je nach Umsetzung im Projekt kann dies auch anders geschehen
    const matomoUrl = <string>config.apis.matomoTagManagerUrl // Url zum umgebungsabhängigen Matomo Script inkl. Container ID z.B. https://analytics.produktion.dvag/js/container<container_id>.js
    const _mtm = (<any>window)._mtm || []

    const script = doc.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    script.src = `${matomoUrl}`

    script.onload = async () => {
      await new Promise(resolve => setTimeout(resolve, 4000)) // 4 Sek. warten bis alles initialisiert ist
      const _mtm = (<any>window)._mtm || []
      _mtm.push({
        'mtm.startTime': (new Date().getTime()),
        'event': 'mtm.Start', // Start-Event für Matomo
        'bearer-token': await keycloak.getToken(), // der Keycloak Token wird in den Datalayer gepusht. Dieser wird dann im Matomo Tag benötigt um den Userservice anzufragen
        'environment': config.environment // Name der genutzten Umgebung. Mögliche Werte: "Lokal" | "Entwicklung" | "Abnahme" | "Integration" | "Produktion"
      })
      _mtm.push({ 'event': 'dvag-init-event' }) // Erst wenn dieses Event gepusht wird, weiß Matomo, dass es den Userservice anfragen kann
    }
    doc.head.appendChild(script) // das zusammengesetzte Script wird in den Head-Bereich injected
  }
}
