import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core'
import { AuthService } from './services/auth.service'
import { ActivationEnd, NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { Components } from '@dvag/design-system'
import { GoogleAnalyticsService, NgxGoogleAnalyticsModule } from 'ngx-google-analytics'
import { searchMandanten, tUser } from './types'
import { Anwendungssuche } from '../generated/openapi/search/anwendungen'
import { AnwendungssucheService } from './services/anwendungssuche.service'
import { TextService } from './services/text.service'
import { Translation } from '../generated/openapi/textservice'
import { EnvironmentService } from './services/environment.service'
import { DesignSystemModule } from '@dvag/design-system-angular'
import { DOCUMENT } from '@angular/common'
import { KeycloakService } from 'keycloak-angular'
import { FooterComponent } from './footer/footer.component'
import { UserlaneComponent } from './userlane/userlane.component'
import { firstValueFrom } from 'rxjs'
import { InsightsService } from './services/insights.service'
import {
  BrowsersizeService,
  HeaderComponent,
  HeaderMenuService,
  tHeaderMenuItem,
  tHeaderMenuLink,
  tHeaderMenuNavigationItem,
  WatchdogService
} from '@dvag/uinfo-ui-lib'
import { Favoriten } from '../generated/openapi/favoriten'
import DxModal = Components.DxModal
import DxSingleSelect = Components.DxSingleSelect

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    DesignSystemModule,
    NgxGoogleAnalyticsModule,
    RouterOutlet,
    FooterComponent,
    UserlaneComponent,
    HeaderComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppComponent implements OnInit {
  isEditor = false
  editMode: boolean = JSON.parse(window.sessionStorage.getItem('text-editmode') || 'false')
  avatar?: URL | string
  logoffUrlKeycloak: URL | string = ''
  logoffUrlMeineDVAG: URL | string = ''
  avatarUrl = ''
  benutzerkontoUrl = ''
  appEditUrl = ''
  appDefaultSearchUrl = ''
  favoritesUrl = ''
  systemstatusUrl: URL | string = ''
  faq: URL | string = ''
  itStatusMeldung: URL | string = ''
  vbweltUrl: URL | string = ''
  beratungsweltUrl: URL | string = ''
  itweltUrl: URL | string = ''
  feedbackformUrl: URL | string = ''
  gtmId = ''
  gtm4Id = ''
  adobeTrackingUrl: URL | string = ''
  favApps: Array<Anwendungssuche> = []
  favorites: Favoriten = {favoritSet: [], vbNummer: ''}
  window = window
  favoritesOpen = false
  userOpen = false
  burgerOpen = false
  textsObjects: Array<Translation> = []
  headerTheme = 'gold'
  showchangerolebutton = false

  @ViewChild('texteditmodal') texteditmodal!: DxModal

  public user: tUser | undefined
  showNavigationHeader = false

  constructor(
    private auth: AuthService,
    public router: Router,
    public gaService: GoogleAnalyticsService,
    private anwendungssucheService: AnwendungssucheService,
    private textService: TextService,
    private envService: EnvironmentService,
    private watchdogService: WatchdogService,
    private _elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private keycloakService: KeycloakService,
    private insightsService: InsightsService,
    private headerMenuService: HeaderMenuService,
    private authService: AuthService,
    private browsersizeService: BrowsersizeService) {
    //
  }

  async ngOnInit() {
    // first do the async stuff
    await this.loadEnv()
    await this.evaluateUser()
    await this.loadApps()

    // activate watchdog and tracking
    this.watchdogService.startWatchdog()
    this.loadAdobeAnalytics()

    // get Texts
    this.textsObjects = this.textService.getTextsObjectsForIds(42, 43, 44, 51, 52, 12, 53, 54, 118, 161, 157, 139, 141, 152, 143, 144, 142, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 156, 155, 157)

    // add Header-Elements
    this.addHeaderMenuLinks()
    this.addHeaderMenuNavigationItems()
    if (this.isEditor) {
      this.addHeaderMenuItems()
    }

    //set initial theme
    this.setHeaderTheme(this.router.url.split('/')[1])

    // subscriptions
    this.router.events.subscribe((event: any) => {
      if (event instanceof ActivationEnd) {
        this.setHeaderTheme(this.router.url.split('/')[1])
      } else if (event instanceof NavigationEnd) {
        this.gaService.gtag('config', this.gtmId, {'page_path': event.url})
        this.gaService.gtag('config', this.gtm4Id, {'page_path': event.url})
      }
    })
    this.anwendungssucheService.favChanged$.subscribe(() => {
      this.favApps = this.anwendungssucheService.favoriteApps
    })
    this.showNavigationHeader = this.browsersizeService.isLowerThanMq('mq3')
    this.browsersizeService.listenOnHigherThanMqSizeChange('mq3').subscribe((isHigher) => {
      this.showNavigationHeader = !isHigher
    })

    // whatever this is...
    this._elementRef.nativeElement.removeAttribute('ng-version')
  }

  loadAdobeAnalytics() {
    const script = this.renderer.createElement('script')
    script.type = 'text/javascript'
    script.src = this.adobeTrackingUrl
    script.async = 'async'
    this.renderer.appendChild(this.document.body, script)
  }

  async loadEnv() {
    const config = await this.envService.getConfig()
    this.logoffUrlKeycloak = config.apis.keycloak + config.apis.keycloakLogout
    this.logoffUrlMeineDVAG = config.apis.logoff
    this.avatarUrl = config.apis.avatar
    this.benutzerkontoUrl = config.apis.benutzerkonto
    this.appEditUrl = config.apis.appEdit
    this.appDefaultSearchUrl = config.apis.appDefaultSearch
    this.favoritesUrl = config.apis.favorites
    this.systemstatusUrl = config.apis.systemstatus
    this.faq = config.apis.faq
    this.itStatusMeldung = config.apis.itStatusMeldung
    this.vbweltUrl = config.apis.vbwelt
    this.beratungsweltUrl = config.apis.beratungswelt
    this.itweltUrl = config.apis.itwelt
    this.feedbackformUrl = config.apis.feedbackForm
    this.gtmId = config.apis.gtmId
    this.gtm4Id = config.apis.gtm4Id
    this.adobeTrackingUrl = config.apis.adobeTracking
  }

  async evaluateUser() {
    this.user = await this.auth.getUser()
    this.isEditor = this.user.isEditor
    const isHauptbenutzer = !this.user.isAssistent && !this.user.isVertreter && !this.user.isInnendienst
    this.showchangerolebutton = !!(isHauptbenutzer && this.user.vertretene?.length) || this.user.isAssistent || this.user.isVertreter
  }

  loadApps(): Promise<void> {
    return new Promise((resolve) => {
      const promises = [
        firstValueFrom(this.anwendungssucheService.fetchApps(searchMandanten['meineDVAG-Keycloak']))
      ]
      const decodedToken = this.authService.getDecodedToken()
      if (decodedToken && decodedToken['user_typ'] === '2') {
        promises.push(firstValueFrom(this.anwendungssucheService.fetchApps(searchMandanten['meineDVAG-ID-Keycloak'])))
      }

      this.anwendungssucheService.favChanged$.subscribe(() => {
        Promise.all(promises).then(() => {
          this.favApps = this.anwendungssucheService.favoriteApps
          this.favorites = {vbNummer: this.user?.userId || '', favoritSet: this.anwendungssucheService.favorites}
          resolve()
        })
      })
    })
  }

  addHeaderMenuLinks() {
    const headerMenuLinks: tHeaderMenuLink[] = [
      {
        id: 'vbwelt',
        label: this.textsObjects[42].toString(),
        callback: () => this.gotoVBWelt(this.vbweltUrl.toString(), 'vbwelt')
      },
      {
        id: 'beratungswelt',
        label: this.textsObjects[43].toString(),
        callback: () => this.gotoBeratungswelt(this.beratungsweltUrl.toString(), 'beratungswelt')
      },
      {
        id: 'itwelt',
        label: this.textsObjects[44].toString(),
        callback: () => this.gotoITWelt(this.itweltUrl.toString(), 'itwelt')
      }
    ]

    // add HeaderMenuLinks
    headerMenuLinks.forEach((headerMenuLink: tHeaderMenuLink) => {
      this.headerMenuService.addHeaderMenuLinksSubject.next(headerMenuLink)
    })
  }

  gotoVBWelt(link: string, target: string) {
    this.gaService.event('click', this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[42] + '>' + 'uid-0088')
    this.insightsService.logEvent(this.textsObjects[42].singular as unknown as string)
    window.open(link, target)
  }

  gotoITWelt(link: string, target: string) {
    this.gaService.event('click', this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[43] + '>' + 'uid-0089')
    this.insightsService.logEvent(this.textsObjects[43].singular as unknown as string)
    window.open(link, target)
  }

  gotoBeratungswelt(link: string, target: string) {
    this.gaService.event('click', this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[44] + '>' + 'uid-0090')
    this.insightsService.logEvent(this.textsObjects[44].singular as unknown as string)
    window.open(link, target)
  }

  addHeaderMenuItems() {
    const headerMenuNavigationItems: tHeaderMenuItem[] = [
      {
        slot: 'menu',
        id: 'textids-anzeigen',
        icon: 'zahnrad',
        title: 'Text-ID\'s anzeigen',
        callback: () =>  { this.texteditmodal.visible = true }
      }
    ]

    // add HeaderMenuItems
    headerMenuNavigationItems.forEach((item) => {
      this.headerMenuService.addHeaderMenuItemsSubject.next(item)
    })
  }

  addHeaderMenuNavigationItems() {
    const headerMenuNavigationItems: tHeaderMenuNavigationItem[] = [
      {
        id: 'startseite',
        label: this.textsObjects[51].toString(),
        size: 'l',
        callback: () => this.gotoHomeNavigation()
      },
      {
        id: 'anwendungsuebersicht',
        label: this.textsObjects[52].toString(),
        size: 'l',
        callback: () => this.gotoAllApps()
      },
      {
        id: 'systemstatusseite',
        label: this.textsObjects[12].toString(),
        size: 'm',
        callback: () => this.gotoSystemstatusseite()
      },
      {
        id: 'faq',
        label: this.textsObjects[53].toString(),
        size: 'm',
        callback: () => this.gotoFaq()
      },
      {
        id: 'statusmeldungen',
        label: this.textsObjects[54].toString(),
        size: 'm',
        callback: () => this.gotoStatusmeldungen()
      },
      {
        id: 'feedback',
        label: this.textsObjects[118].toString(),
        size: 'm',
        position: 'footer',
        type: 'button',
        callback: () => this.gotoFeedbackForm()
      }
    ]

    // add HeaderMenuNavigationItems
    headerMenuNavigationItems.forEach((headerMenuNavigationItem: tHeaderMenuNavigationItem) => {
      this.headerMenuService.addHeaderMenuNavigationItemSubject.next(headerMenuNavigationItem)
    })
  }

  public setHeaderTheme(path: string) {
    this.headerTheme = ((path === '' || path.startsWith('#')) ? 'gold' : 'white')
  }

  gotoHomeNavigation() {
    this.insightsService.logEvent(this.textsObjects[155].singular as unknown as string)
    this.gaService.event(this.textsObjects[155].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[155] + '>' + this.textsObjects[139] + '>' + 'uid-0077')
    this.router.navigate(['/']).then()
  }

  gotoAllApps() {
    this.gaService.event(this.textsObjects[156].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[156] + '>' + 'uid-0078')
    this.insightsService.logEvent(this.textsObjects[156].singular as unknown as string)
    this.router.navigate(['/apps']).then()
  }

  gotoStatusmeldungen() {
    this.gaService.event(this.textsObjects[151].singular + '', this.textsObjects[161].singular + ':' + this.textsObjects[157].singular + '>' + this.textsObjects[151].singular + '>' + 'uid-0064')
    window.open(this.itStatusMeldung, '_blank')
  }

  gotoFaq() {
    this.gaService.event(this.textsObjects[150].singular + '', this.textsObjects[161].singular + ':' + this.textsObjects[157].singular + '>' + this.textsObjects[150].singular + '>' + 'uid-0063')
    window.open(this.faq, '_blank')
  }

  gotoSystemstatusseite() {
    this.gaService.event(this.textsObjects[149].singular + '', this.textsObjects[161].singular + ':' + this.textsObjects[157].singular + '>' + this.textsObjects[149].singular + '>' + 'uid-0062')
    window.open(this.systemstatusUrl, '_blank')
  }

  gotoFeedbackForm() {
    this.router.navigate([]).then(() => {
      window.open(this.feedbackformUrl, '_blank')
    })
    this.insightsService.logEvent('Click NavFeedbackButton')
  }

  confirmText(modal: DxModal, editmodeToggle: DxSingleSelect) {
    this.editMode = (editmodeToggle.value === 'yes')
    window.sessionStorage.setItem('text-editmode', this.editMode.toString())
    modal.visible = false
    this.reloadRoute()
  }

  editModeToYesNo(editmode: boolean): 'yes' | 'no' {
    return ((editmode && 'yes') || (!editmode && 'no') || 'no')
  }

  reloadRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.router.onSameUrlNavigation = 'reload'
    this.router.navigate([this.router.url]).then()
  }

  gotoHomeLogo() {
    this.insightsService.logEvent(this.textsObjects[139].singular as unknown as string)
    this.router.navigate(['/']).then()
    this.gaService.event(this.textsObjects[139].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[139] + '>' + 'uid-0053')
  }

  changeUser() {
    const loginParms = {
      action: 'CONTEXT_CHANGE',
      scope: 'context_profile'
    }
    this.keycloakService.login(loginParms).then()
  }

  writeGAFavorites() {
    if (this.favoritesOpen) {
      this.gaService.event(this.textsObjects[152].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[141] + '>' + this.textsObjects[152] + '>' + 'uid-0065')
      this.favoritesOpen = false
    } else {
      this.gaService.event(this.textsObjects[141].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[141] + '>' + 'uid-0054')
      this.favoritesOpen = true
    }
  }

  writeGAUser() {
    if (this.userOpen) {
      this.gaService.event(this.textsObjects[152].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[142] + '>' + this.textsObjects[152] + '>' + 'uid-0066')
      this.userOpen = false
    } else {
      this.gaService.event(this.textsObjects[142].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[142] + '>' + 'uid-0055')
      this.userOpen = true
    }
  }

  writeGABurger() {
    if (this.burgerOpen) {
      this.gaService.event(this.textsObjects[152].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[143] + '>' + this.textsObjects[152] + '>' + 'uid-0067')
      this.burgerOpen = false
    } else {
      this.gaService.event(this.textsObjects[143].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[143] + '>' + 'uid-0056')
      this.burgerOpen = true
    }
  }

  trackEditApps() {
    this.gaService.event(this.textsObjects[148].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[148] + '>' + 'uid-0061')
    this.insightsService.logEvent(this.textsObjects[148].singular as unknown as string)
  }

  trackGotoApps() {
    this.gaService.event(this.textsObjects[147].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[147] + '>' + 'uid-0060')
    this.insightsService.logEvent(this.textsObjects[147].singular as unknown as string)
    this.router.navigate(['/apps']).then()
  }

  trackLaunchApp(app: Anwendungssuche) {
    const appid = app.id
    const appname = this.favApps.filter((app) => app.id === appid)[0]?.title
    this.gaService.event(this.textsObjects[146].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[146] + '>' + 'uid-0059', appname)
  }

  trackSortFavorites() {
    this.gaService.event(this.textsObjects[154].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[154] + '>' + 'uid-0069')
    this.insightsService.logEvent(this.textsObjects[154].singular as unknown as string)
  }

  trackDeleteFavorite() {
    this.gaService.event(this.textsObjects[153].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[153] + '>' + 'uid-0068')
    this.insightsService.logEvent(this.textsObjects[153].singular as unknown as string)
  }

  trackGotoBenutzerkonto() {
    this.gaService.event(this.textsObjects[144].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[144] + '>' + 'uid-0057')
    this.insightsService.logEvent(this.textsObjects[144].singular as unknown as string)
  }

  trackGotoLogoff() {
    this.gaService.event(this.textsObjects[145].singular as unknown as string, this.textsObjects[161] + ':' + this.textsObjects[157] + '>' + this.textsObjects[145] + '>' + 'uid-0058')
  }
}
