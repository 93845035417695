<dx-app>
  <dx-header id="meineDvagHeader" appname="Meine DVAG" slot="header" (navigateHome)="gotoHomeLogo()" [theme]="headerTheme">
    <dx-header-menu-group slot="menu" priority="3">
      <dx-header-menu-link label="{{ textsObjects[42] }}" (menuInteraction)="gotoVBWelt()"></dx-header-menu-link>
      <dx-spacer size="16h"></dx-spacer>
      <dx-header-menu-link label="{{ textsObjects[43] }}" (menuInteraction)="gotoBeratungswelt()"></dx-header-menu-link>
      <dx-spacer size="16h"></dx-spacer>
      <dx-header-menu-link label="{{ textsObjects[44] }}" (menuInteraction)="gotoITWelt()"></dx-header-menu-link>
      <dx-spacer size="32h"></dx-spacer>
    </dx-header-menu-group>
    @if (user.context?.vorname && user.context?.name) {
      @if (this.user.isVertreter) {
        <dx-representative-info slot="representative" type="representative" [name]="user.context?.vorname + ' ' + user.context?.name"></dx-representative-info>
      }
      @if (this.user.isAssistent) {
        <dx-representative-info slot="representative" type="assistant" [name]="user.context?.vorname + ' ' + user.context?.name"></dx-representative-info>
      }
    }
    @if (isEditor) {
      <dx-header-menu-item slot="menu" icon="zahnrad" (click)="texteditmodal.visible = true" title="Text-ID's anzeigen"></dx-header-menu-item>
    }
    <dx-header-app-favorites
      priority="2"
      id="qs-app-favorites"
      slot="menu"
      (launchApp)="launchApp($event.detail); appFavorites.closeSidebar()"
      (addApp)="gotoApps(); appFavorites.closeSidebar()"
      (sortApps)="saveFavorites($event.detail)"
      (deleteApp)="removeFav($event.detail)"
      (editApps)="editApps()"
      (menuInteraction)="writeGAFavorites()" #appFavorites>
      @for (app of favApps; track app) {
        <dx-header-app-favorites-app
          [appid]="app.id"
          [appname]="app.title"
          [icon]="app.iconName">
        </dx-header-app-favorites-app>
      }
    </dx-header-app-favorites>
    <dx-header-user
      priority="1"
      slot="menu"
      [name]="fullname"
      [imageurl]="avatar"
      accountavailable="{{!user.isInnendienst && !user.isAssistent && !user.isVertreter}}"
      [showchangerolebutton]="showchangerolebutton"
      (accountInteraction)="gotoBenutzerkonto()"
      (changeRoleInteraction)="changeUser()"
      (signOut)="gotoLogoff()"
      (menuInteraction)="writeGAUser()"
    ></dx-header-user>
    @if (browsersizeService.isLowerThan.mq2) {
      <div class="navigationHeader" slot="navigation-header">
        <div class="navigationHeader__welten">
          <dx-text (click)="gotoVBWelt()" class="navigationHeader__button" color="blue-80" type="Infotext-Standard">{{textsObjects[42]}}</dx-text>
          <dx-text (click)="gotoBeratungswelt()" class="navigationHeader__button" color="blue-80" type="Infotext-Standard">{{textsObjects[43]}}</dx-text>
          <dx-text (click)="gotoITWelt()" class="navigationHeader__button" color="blue-80" type="Infotext-Standard">{{textsObjects[44]}}</dx-text>
        </div>
      </div>
    }
    <dx-header-navigation slot="menu" (menuInteraction)="writeGABurger()">
      <dx-header-navigation-item
        size="l"
        label="{{textsObjects[51]}}"
        (interaction)="gotoHome()">
      </dx-header-navigation-item>
      <dx-header-navigation-item
        size="l"
        label="{{textsObjects[52]}}"
        (interaction)="allApps(); appFavorites.closeSidebar()">
      </dx-header-navigation-item>
      <dx-header-navigation-item
        size="m"
        label="{{textsObjects[12]}}"
        (interaction)="window.open(systemstatusUrl, '_blank'); this.gaService.event(this.textsObjects[149].singular + '', this.textsObjects[161].singular + ':' + this.textsObjects[157].singular + '>' + this.textsObjects[149].singular + '>' + 'uid-0062')">
      </dx-header-navigation-item>
      <dx-header-navigation-item
        size="m"
        label="{{textsObjects[53]}}"
        (interaction)="window.open(faq, '_blank'); this.gaService.event(this.textsObjects[150].singular + '', this.textsObjects[161].singular + ':' + this.textsObjects[157].singular + '>' + this.textsObjects[150].singular + '>' + 'uid-0063')">
      </dx-header-navigation-item>
      <dx-header-navigation-item
        size="m"
        label="{{textsObjects[54]}}"
        (interaction)="window.open(itStatusMeldung, '_blank'); this.gaService.event(this.textsObjects[151].singular + '', this.textsObjects[161].singular + ':' + this.textsObjects[157].singular + '>' + this.textsObjects[151].singular + '>' + 'uid-0064')">
      </dx-header-navigation-item>
    </dx-header-navigation>
    <div slot="navigation-footer" class="navigation-footer">
      <dx-button type="primary-m" label="{{textsObjects[118]}}" (click)="gotoFeedbackForm()"></dx-button>
    </div>
  </dx-header>
  <dx-modal id="text-edit-modal" height="content" label="Textbearbeitung" #texteditmodal>
    <div slot="content">
      <dx-single-select id="text-editmode-toggle" label="Text-ID's anzeigen:" size="s" [value]="editModeToYesNo(editMode)" #editmodetoggle>
        <dx-single-select-item value="yes" label="Ja"></dx-single-select-item>
        <dx-single-select-item value="no" label="Nein"></dx-single-select-item>
      </dx-single-select>
    </div>
    <dx-button slot="primary-actions" type="primary-m" id="text-edit-modal-confirm" label="Bestätigen" (click)="confirmText(texteditmodal, editmodetoggle)">
    </dx-button>
  </dx-modal>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
  <app-userlane></app-userlane>
</dx-app>
